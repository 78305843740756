<template>
    <b-form @submit.prevent>
        <b-row>


          <!-- email -->
          <b-col cols="5">

            <b-form-group label="Target Address" label-for="vi-email" >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text> <feather-icon icon="GridIcon" /> </b-input-group-prepend>
                <b-form-input v-model="address" placeholder="Address"/>
              </b-input-group>
            </b-form-group>

            <b-form-group label="New Status"  >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text> <feather-icon icon="WindIcon" /> </b-input-group-prepend>
                <b-form-select v-model="voucherStatus" :options="VoucherStatusChoice" />                
              </b-input-group>
            </b-form-group>

          </b-col>

          <b-col cols="3">
            <b-form-group label="Token Ids" >
              <b-input-group class="input-group-merge">
                <b-form-textarea  v-model="tokenIds" placeholder="Token Ids seperate by ; or new line" rows="4" max-rows="8" />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- reset and submit -->
          <b-col cols="12">
                <ButtonSpinner variant="primary" class="mr-1" :isLoading="updating" :disabled="getContract === null" @click.native="update" loadMessage="Updating">
                  Update
                </ButtonSpinner>
          </b-col>
        </b-row>
    </b-form>
</template>

<script>

import BCardCode from '@core/components/b-card-code'

import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BFormSelect
} from 'bootstrap-vue'

import ButtonSpinner from '@core/components/button-spinner/ButtonSpinner.vue'

import { mapActions, mapGetters } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ethers } from 'ethers'

export default {
    data: function() {
        return {
            tokenIds: "",
            address: "",
            voucherStatus: 0,
            updating: false           
        }
    },
    computed: {
        ...mapGetters("JokerCharlieVoucher", [ "getContract", "VoucherStatusChoice" ])
    },
    methods: {      
        ...mapActions("logs", ["logToast"] ),
        showToast: function(variant, title, message, icon) {
            if (icon === undefined) icon = "InfoIcon"
            this.logToast(variant, title, message)
            this.$toast({
                component: ToastificationContent,
                props: { title: title, text: message, variant, icon },
            })
        },        
        update: async function() {            
            try {
                ethers.utils.getAddress(this.address)
            } catch (e) {
                this.showToast('danger', 'Transaction failed', 'Invalid address')
                return;
            }
            let contract = this.getContract
            if (!contract) {
                this.showToast('danger', 'Transaction failed', 'Invalid contract')
                return            
            }
            // parsing tokenIds
            let ids = []
            try {
              this.tokenIds.split('\n').forEach((t) => {
                t.split(';').forEach((t) => {
                  t = t.trim()
                  if (t === "") return
                  ids.push(ethers.BigNumber.from(t))
                })              
              })
            } catch (e) {
              this.showToast('danger' ,'Transaction failed', 'Invalid token Ids')
              return
            }    
            if (ids.length === 0) {
              this.showToast('danger' ,'Transaction failed', 'Invalid token Ids')
              return
            }
            this.updating = true
            try {                
                let tx = await contract["updateVoucher(uint256[],uint8,address)"](ids, this.voucherStatus, this.address)
                this.showToast('info', 'Transaction sent', `UpdateVoucher transaction is successfully sent to JokerCharlieVoucher contract with hash ${tx.hash}`)
                let receipt = await tx.wait()
                this.showToast('succcess', 'Transaction complete', `UpdateVoucher transaction is completed. Blockhash: ${receipt.blockHash}. TxHash: ${receipt.transactionHash}`)
            } catch (e) {
                console.log(e)
                this.showToast('danger', 'Transaction failed', e.message)
            }
            this.updating = false            
        },

    },
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BInputGroup,
        BInputGroupPrepend,
        BForm,
        BButton,
        BFormSelect,
        ToastificationContent,
        ButtonSpinner,
        BFormTextarea
    }
}
</script>