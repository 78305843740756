<template>
  <b-form @submit.prevent>
    <b-row>

      <!-- first name -->
      <b-col cols="5">
        <b-form-group label="Wallet address">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text><feather-icon icon="GridIcon" /></b-input-group-prepend>
            <b-form-input v-model="address" placeholder="Wallet address" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- email -->
      <b-col cols="3">
        <b-form-group label="Number of Voucher"  >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text><feather-icon icon="HashIcon" /></b-input-group-prepend>
            <b-form-input  v-model="numberOfVoucher" type="number" placeholder="Number of Voucher"/>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- mobile -->
      <b-col cols="5">
        <b-form-group label="Joker Type" label-for="vi-mobile" >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text><feather-icon icon="UserIcon" /></b-input-group-prepend>
            <b-form-select v-model="jokerType" :options="JokerTypeChoice" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- password -->
      <b-col cols="3">
        <b-form-group label="Joker Amount" label-for="vi-password" >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text><feather-icon icon="HashIcon" /></b-input-group-prepend>
            <b-form-input  v-model="numberOfJoker" type="number" placeholder="Joker Amount" />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- reset and submit -->
      <b-col cols="12">
        <ButtonSpinner variant="primary" class="mr-1" :isLoading="minting" :disabled="getContract === null" @click.native="mint" loadMessage="Minting">
          Mint
        </ButtonSpinner>
      </b-col>      
    </b-row>
  </b-form>
</template>

<script>

import BCardCode from '@core/components/b-card-code'

import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BFormSelect
} from 'bootstrap-vue'

import ButtonSpinner from '@core/components/button-spinner/ButtonSpinner.vue'

import { mapActions, mapGetters } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ethers } from 'ethers'

export default {
    data: function() {
        return {
            address: "",
            numberOfVoucher: 1,
            numberOfJoker: 1,
            jokerType: 0, 
            minting: false           
        }
    },
    computed: {
        ...mapGetters("JokerCharlieVoucher", [ "getContract", "JokerTypeChoice" ])
    },
    methods: {      
        ...mapActions("logs", ["logToast"] ),
        mint: async function() {            
            try {
                ethers.utils.getAddress(this.address)
            } catch (e) {
                this.showToast('danger', 'Transaction failed', 'Invalid address')
                return;
            }
            let contract = this.getContract
            if (!contract) {
                this.showToast('danger', 'Transaction failed', 'Invalid contract')
                return            
            }
            this.minting = true
            try {
                let tx = await contract.mint(this.address, this.numberOfVoucher, this.jokerType, this.numberOfJoker)
                this.showToast('info', 'Transaction sent', `Mint transaction is successfully sent to JokerCharlieVoucher contract with hash ${tx.hash}`)
                let receipt = await tx.wait()
                this.showToast('succcess', 'Transaction complete', `Mint transaction is completed. Blockhash: ${receipt.blockHash}. TxHash: ${receipt.transactionHash}`)
            } catch (e) {
                console.log(e)
                this.showToast('danger', 'Transaction failed', e.message)
            }
            this.minting = false
        },
        showToast: function(variant, title, message, icon) {
            if (icon === undefined) icon = "InfoIcon"
            this.logToast(variant, title, message)
            this.$toast({
                component: ToastificationContent,
                props: { title: title, text: message, variant, icon },
            })
        },
    },
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BInputGroup,
        BInputGroupPrepend,
        BForm,
        BButton,
        BFormSelect,
        ToastificationContent,
        ButtonSpinner
    }
}
</script>