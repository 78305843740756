<template>
  <b-card-code title="Voucher Detail">

      <!-- advance search input -->
      <b-row class="pb-1">
        <b-col md="12">
           Total Supply: {{ totalSupply }}
        </b-col>
        <b-col md="12">
           Voucher: 
           <template v-for="(item, index) in voucherDetail">
              <span> {{ item.name }}( {{ item.amount }} / {{ item.maxAmount }} )</span>
           </template>
        </b-col>
      </b-row>

      <b-form @submit.prevent>
        <b-row class="pb-1">
          <b-col cols="6">            
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text><feather-icon icon="GridIcon" /></b-input-group-prepend>
              <b-form-input v-model="address" placeholder="Wallet address" />
            </b-input-group>
          </b-col>
          <b-col cols="3">
            <ButtonSpinner variant="primary" class="mr-1 form-control" :isLoading="loading.address" :disabled="getContract === null" @click.native="loadAddress" :loadMessage="message.address">
              Load Address
            </ButtonSpinner>            
          </b-col>
          <b-col cols="3">
            <ButtonSpinner variant="info" class="mr-1 form-control" :isLoading="loading.all" :disabled="getContract === null" @click.native="loadAll" :loadMessage="message.all">
              Load All
            </ButtonSpinner>            
          </b-col>
        </b-row>
        <b-row class="pb-1">
          <b-col cols="6">            
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text><feather-icon icon="HashIcon" /></b-input-group-prepend>
              <b-form-input v-model="tokenIds" placeholder="Voucher Ids seperate by ;" />
            </b-input-group>
          </b-col>
          <b-col cols="3">
            <ButtonSpinner variant="primary" class="mr-1 form-control" :isLoading="loading.voucher" :disabled="getContract === null" @click.native="loadIds" :loadMessage="message.voucher">
              Load Vouchers
            </ButtonSpinner>            
          </b-col>
          <b-col cols="3">
            <b-form-input placeholder="Search"  type="text" class="d-inline-block"  @input="advanceSearch"  />
          </b-col>
        </b-row>
      </b-form>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns" :rows="rows" :search-options="{ enabled: true, externalQuery: searchTerm }"
      :select-options="{
        // enabled: true,
        // selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onRowClick"
    >
      <template slot="table-row" slot-scope="props">
        <span class="text-center">
          <h6>{{ props.row[props.column.field] }}</h6>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select v-model="pageLength" :options="['5','10','25', '50']"  class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})" />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
            &nbsp;<button @click="reset">reset</button>
            &nbsp;<button @click="refresh">refresh</button>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
              <template #prev-text> <feather-icon icon="ChevronLeftIcon"  size="18" />  </template>
              <template #next-text> <feather-icon icon="ChevronRightIcon" size="18" />  </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code title="Advance Search Table">
</template>

<script>

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BForm, BInputGroup, BInputGroupPrepend
} from 'bootstrap-vue'

import ButtonSpinner from '@core/components/button-spinner/ButtonSpinner.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'

import { mapActions, mapGetters } from 'vuex'

import { ethers } from 'ethers'

export default {
    components: {
        BCardCode, VueGoodTable, ToastificationContent, ButtonSpinner, 
        BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BForm, BInputGroup, BInputGroupPrepend
    },
    data() {
        return {
            pageLength: 25,  
            address: "",
            tokenIds: "",          
            totalSupply: "-",
            voucherDetail: [],
            columns: [  { label: 'ID',    field: 'id',   },
                        { label: 'Type',   field: 'type',     },
                        { label: 'Amount', field: 'amount',   },
                        { label: 'Status', field: 'status',   },
                        { label: 'Owner',  field: 'owner',    },
                        { label: 'Target', field: 'target',   },
            ],
            data: {},
            rows: [],
            message: {
                address: "",
                voucher: "",
                all: "",
            },
            loading: {
                address: false,
                voucher: false,
                all: false,
            },
            searchTerm: '',
        }
    },
    created() {
        // this.$http.get('/good-table/advanced-search')
        //  .then(res => { this.rows = res.data })
    },
    computed: {        
        ...mapGetters("JokerCharlieVoucher", ["getContract"] ),
        ...mapGetters("JokerCharlieVoucher", ["VoucherStatusMap","VoucherStatusChoice","JokerTypeMap","JokerTypeChoice"] ),
    },
    methods: {        
        ...mapActions("logs", ["logToast"]),  
        showToast: function(variant, title, message) {
            this.logToast(variant, title, message)
            this.$toast({
                component: ToastificationContent,
                props: { title: title, text: message, variant },
            })
        }, 
        async loadIds() {
            let contract = this.getContract
            if (!contract) {
                this.showToast('danger','Error','Contract is not ready')
                return
            }
            let ids = []
            this.tokenIds.split(';').forEach((t)=>{
                t = t.trim()
                if (t !== "") ids.push(t)
            })
            this.loading.voucher = true 
            try {                
                if (ids.length === 0) {
                    this.showToast('info', 'No voucher load', 'No voucher load')                    
                } else {                                 
                    for (var i = 0; i < ids.length; i++)  {
                        this.message.voucher = `Loading ${i+1}/${ids.length}`
                        await this.fetchVoucherDetail(ids[i])                        
                    }
                    this.updateTable()
                }
            } catch (e) {
                this.showToast('danger','Error', e.message)
            }
            this.loading.voucher = false            
        },
        async loadAddress() {
            let contract = this.getContract
            if (!contract) {
                this.showToast('danger','Error','Contract is not ready')
                return
            }
            try {
                ethers.utils.getAddress(this.address)
            } catch (e) {
                this.showToast('danger', 'Transaction failed', 'Invalid address')
                return;
            }           
            this.loading.address = true 
            try {                
                let ids = await contract.walletOfOwner(this.address)
                if (ids.length === 0) {
                    this.showToast('info', 'Wallet contain no voucher', 'No voucher load')                    
                } else {                     
                    for (var i = 0; i < ids.length; i++)  {
                        this.message.address = `Loading ${i+1}/${ids.length}`
                        await this.fetchVoucherDetail(ids[i])
                    }
                    this.updateTable()
                }
            } catch (e) {
                this.showToast('danger','Error', e.message)
            }
            this.loading.address = false
        },
        async loadAll() {
            let contract = this.getContract
            if (!contract) {
                this.showToast('danger','Error','Contract is not ready')
                return
            }
            this.loading.all = true 
            try {                                
                for (var i = 0; i < this.totalSupply; i++)  {
                    this.message.all = `Loading ${i+1}/${this.totalSupply}`
                    await this.fetchVoucherDetail(i+1)
                }
                this.updateTable()
            } catch (e) {
                this.showToast('danger','Error', e.message)
            }
            this.loading.all = false
        },
        updateTable() {
            let rows = []
            for (var id in this.data) {
                rows.push( this.data[id])                
            }
            this.rows = rows
        },
        async updateValue() {
            let contract    = this.getContract
            if (!contract) return
            let amounts     = await contract.getVoucherAmount()
            let maxAmounts  = await contract.getMaximumVoucherAmount()
            let totalSupply = await contract.totalSupply()
            let voucherDetail = [ ]            
            for (var i = 0; i < amounts.length; i++) {
                voucherDetail.push({name: this.JokerTypeMap[i], amount: amounts[i], maxAmount: maxAmounts[i]})
            }
            this.totalSupply   = totalSupply
            this.voucherDetail = voucherDetail
        },
        fetchVoucherDetail: async function(id) {            
            try {
                let result = await this.getContract.getVoucherDetail(id)
                let detail = {
                    id: id,
                    type: this.JokerTypeMap[result[0]],
                    amount: result[1],
                    status: this.VoucherStatusMap[result[2]],
                    owner: result[3],
                    minter: result[4],
                    original: result[5],
                    setter: result[6],
                    target: result[7]
                }                
                this.data[id] = detail
                return detail
            } catch (e) {
                this.showToast('danger',`Error: cannot fetch Voucher ${id}`, e.message)
            }
        },
        advanceSearch(val) {
            this.searchTerm = val
        },
        async onRowClick(params) {            
            this.fetchVoucherDetail(params.row.id).then(()=>{
                this.updateTable()
            })            
        },
        reset() {
            this.data = {}
            this.rows = []
        },
        refresh() {

        }
    },
    watch: {
        getContract: function() { this.updateValue() }
    }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>